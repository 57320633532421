import React, { useCallback, useMemo, useState } from "react";
import { Button } from "@commonsku/styles";
import { useHasCapabilities } from "../../hooks";
import SupplierVendorCreditPopup from "../vendor-credit/SupplierVendorCreditPopup";
import { getDefaultSupplierVendorCredit } from "../../redux/vendor_credits";
import { useReportContext } from "../report/report_context";
import useCanadianTax from "../../hooks/useCanadianTax";

function CreateVendorCreditButton() {
  const hasCreateVendorCredit = useHasCapabilities(["CREATE-VENDOR-CREDIT"]);
  const [showCreateVendorCredit, setShowCreateVendorCredit] = useState(false);
  const { isCanadaQBO, defaultZeroTaxId } = useCanadianTax();
  const { fetchReport } = useReportContext();

  const vendorCredit = useMemo(
    () => getDefaultSupplierVendorCredit(isCanadaQBO ? defaultZeroTaxId : ""),
    [isCanadaQBO, defaultZeroTaxId],
  );

  const handleClose = useCallback(() => {
    fetchReport();
    setShowCreateVendorCredit(false);
  }, [fetchReport]);

  if (!hasCreateVendorCredit) {
    return null;
  }

  return (
    <>
      {showCreateVendorCredit && (
        <SupplierVendorCreditPopup
          vendorCredit={vendorCredit}
          onClose={handleClose}
        />
      )}
      <Button secondary onClick={() => setShowCreateVendorCredit(true)}>
        Create Vendor Credit
      </Button>
    </>
  );
}

export default CreateVendorCreditButton;
