import React, { useCallback, useMemo, useState } from "react";
import { Button } from "@commonsku/styles";
import { useHasCapabilities } from "../../hooks";
import ClientCreditMemoPopup from "../credit-memo/ClientCreditMemoPopup";
import { getDefaultClientCreditMemo } from "../../redux/credit_memos";
import { useReportContext } from "../report/report_context";
import useCanadianTax from "../../hooks/useCanadianTax";

function CreateCreditMemoButton() {
  const hasCreateCreditMemo = useHasCapabilities(["CREATE-CREDIT-MEMO"]);
  const [showCreateCreditMemo, setShowCreateCreditMemo] = useState(false);
  const { isCanadaQBO, defaultZeroTaxId } = useCanadianTax();
  const { fetchReport } = useReportContext();

  const creditMemo = useMemo(
    () => getDefaultClientCreditMemo(isCanadaQBO ? defaultZeroTaxId : ""),
    [isCanadaQBO, defaultZeroTaxId],
  );

  const handleClose = useCallback(() => {
    fetchReport();
    setShowCreateCreditMemo(false);
  }, [fetchReport]);

  if (!hasCreateCreditMemo) {
    return null;
  }

  return (
    <>
      {showCreateCreditMemo && (
        <ClientCreditMemoPopup
          creditMemo={creditMemo}
          onClose={handleClose}
        />
      )}
      <Button secondary onClick={() => setShowCreateCreditMemo(true)}>
        Create Credit Memo
      </Button>
    </>
  );
}

export default CreateCreditMemoButton;
